<template>
    <v-row no-gutters>
      <v-col>      
  
      <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />
  
      <v-alert v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer" text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
        Lütfen yukarıdan bir müşteri seçin
      </v-alert>
  
      <div v-else>
  
          <v-expand-transition>
            <v-card
              v-if="status"
              :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
              rounded="xl"
              class="mx-auto mt-md-9"
              :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
            >
              <v-img
                class="white--text align-end"
                :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                src="@/assets/images/pages/header-01.jpg"
                :height="$vuetify.breakpoint.smAndDown ? 260 : 260"
                gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
              >
                <div class="card-img--text card-img--text-type-1">
                  <div class="text-h4 font-weight-medium mb-0">Satış Raporu</div>
                  <p class="text-body-2 white--text font-weight-thin mb-1" v-text="msg" />
                  <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
                </div>
              </v-img>
              <v-expand-transition>
                <div v-show="!loading">
                  <v-card-text class="pa-0">
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : 'pt-6'">
                      <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted1"
                              label="Başlangıç Tarihi"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
  
                          <v-date-picker
                            v-model="date1"
                            no-title
                            color="primary"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              label="Bitiş Tarihi"
                              persistent-hint
                              :prepend-icon="icons.mdiCalendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
  
                          <v-date-picker v-model="date2" no-title color="primary" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : 'pt-6'">   
                        <v-col cols="11" class="pl-md-9 pr-md-2 px-9">                          
                          <v-text-field v-model="docMaterial" hide-details label="Malzeme" ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-divider v-if="!$vuetify.breakpoint.smAndDown"></v-divider> -->
                  </v-card-text>
  
                  <v-card-actions class="pb-9 pt-9">
                    <v-spacer />
                    <v-btn
                      color="secondary"
                      class="text-capitalize text-body-1"
                      rounded
                      x-large
                      @click="fetchDatas()"
                    >
                      Ara
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expand-transition>
  
  
          <v-card
            v-if="!status"
            tile
            :flat="$vuetify.breakpoint.mdAndDown"
            :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
          >
            <v-toolbar :color="isDark ? '#312d4b' : '#fafafa'"  :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
              <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
                <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                  {{ icons.mdiFinance }}
                </v-icon>
               Satış Raporu
              </v-toolbar-title>
  
              <v-spacer></v-spacer>
  
               <download-excel
                v-if="!$vuetify.breakpoint.smAndDown"
                :data="json_data"
                :fields="json_fields"
                name="SALES_REPORT__TUKAS.xls"
              >
                <v-btn plain text small>
                  <v-icon left>
                    {{ icons.mdiDownload }}
                  </v-icon>
                  İndir
                </v-btn>
              </download-excel>
      
            </v-toolbar>
  
            <!-- table -->
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="dataListTable"
              :options.sync="options"
              :items-per-page="15"
              
              fixed-header
              :height="dataTableHeight"
              :loading="loading"           
            >
            

              <template #[`item.DOCTYPE`]="{ item }">
                <v-chip small label color="grey">
                  <span :class="{ 'white--text': isDark, 'black--text': !isDark }"> {{ item.DOCTYPE }}</span>
                </v-chip>
              </template>
              <template #[`item.DOCNUM`]="{ item }">
                <v-chip small label color="grey">
                  <span :class="{ 'white--text': isDark, 'black--text': !isDark }">{{ item.DOCNUM }}</span>
                </v-chip>
              </template>
              <template #[`item.ITEMNUM`]="{ item }">
               
                <div class="text-caption font-weight-medium ls-0">
                  {{ item.ITEMNUM}}
                </div>
              </template>
              <template #[`item.DOCDATE`]="{ item }">
                <div class="text-caption font-weight-medium ls-0">
                    {{ item.DOCDATE.slice(0, 10) }}
                  </div>
              </template>
              <template #[`item.BUSAREA`]="{ item }">                
                <div class="text-caption font-weight-medium ls-0">
                    {{ item.BUSAREA }}
                  </div>
              </template>
              
              <template #[`item.CUSTOMER`]="{ item }">
                <span class="success--text text-caption font-weight-bold"> {{ item.CUSTOMER }}</span>
              </template>
  
           
              <template #[`item.NAME1`]="{ item }">         
                  <span class="success--text text-caption font-weight-bold"> {{ item.NAME1}}</span>              
              </template>


              <template #[`item.CENTRAL`]="{ item }">                
                  <span class="success--text text-caption font-weight-bold"> {{ item.CENTRAL}}</span>
             
              </template>


              <template #[`item.CUSTGRP`]="{ item }">              
                  <span class="text--secondary"> {{ item.CUSTGRP }}</span>              
              </template>
              <template #[`item.COUNTRY`]="{ item }">               
                  <span class="text--secondary"> {{ item.COUNTRY }}</span>               
              </template>
              <template #[`item.SALDEPT`]="{ item }">            
                  <span class="text--secondary"> {{ item.SALDEPT }}</span>             
              </template>            
            
  
              <template #[`item.SALDTXT`]="{ item }">              
                  <span class="text--secondary"> {{ item.SALDTXT}}</span>          
              </template>     
              <template #[`item.MATERIAL`]="{ item }">                
                  <span class="text--secondary"> {{ item.MATERIAL}}</span>               
              </template>    
              <template #[`item.MTEXT`]="{ item }">              
                  <span class="text--secondary"> {{ item.MTEXT}}</span>              
              </template>    
              <template #[`item.MATTYPE`]="{ item }">
             
                  <span class="text--secondary"> {{ item.MATTYPE}}</span>               
              </template> 
              <template #[`item.MATGRP`]="{ item }">            
                  <span class="text--secondary"> {{ item.MATGRP}}</span>               
              </template> 
              <template #[`item.DOCCURRENCY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="error--text text-caption font-weight-bold"> {{ item.DOCCURRENCY }}</span>
                </v-chip>
              </template>
              
              <template #[`item.QUANTITY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.QUANTITY }}</span>
                </v-chip>
              </template>              

             
              <template #[`item.QUNIT`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.QUNIT}}</span>
                </v-chip>
              </template> 

              <template #[`item.SPRICE`]="{ item }">
                <v-chip small label color="grey">
                    <span class="success--text text-caption font-weight-bold"> {{  item.SPRICE | currency }}</span>
                  </v-chip>              
              </template> 
              

              <template #[`item.EXHR`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.EXHR}}</span>
                </v-chip>
              </template> 
               <template #[`item.GROSS`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.GROSS| currency }}</span>
                </v-chip>
              </template> 
              <template #[`item.REPCURRENCY`]="{ item }">
                <v-chip small label color="grey">
                  <span class="secondary--text text-caption font-weight-bold"> {{ item.REPCURRENCY}}</span>
                </v-chip>
              </template> 
              <template #[`item.TDISCAMNT`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.TDISCAMNT| currency }}</span>
                </v-chip>
              </template> 
              <template #[`item.DISCFROMHEAD`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.DISCFROMHEAD | currency }}</span>
                </v-chip>
              </template> 
              <template #[`item.SUBTOTAL`]="{ item }">               
              
                  <div class="text-right">
                    <v-chip small label color="grey">
                      <span class="success--text text-caption font-weight-bold"> 
                        {{ item.SUBTOTAL | currency }}
                      </span>
                    </v-chip>
                  </div>

              </template> 
              <template #[`item.CURRENCYORJ`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.CURRENCYORJ }}</span>
                </v-chip>
              </template> 
          
              <template #[`item.ITENDORSE`]="{ item }">
                <v-chip small label color="grey">
                  <span class="text--secondary"> {{ item.ITENDORSE | currency }}</span>
                </v-chip>
              </template>        
              <template v-if="!$vuetify.breakpoint.smAndDown" #body.append>
                <tr class="font-weight-bold">
                  <td colspan="1" class="text-right">{{tableFooter[0].DOCDATE}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].DOCNUM}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].ITEMNUM}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].BUSAREA}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].BUSAREA}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].CUSTOMER}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
                  <td colspan="1" class="text-right">{{tableFooter[0].NAME1}}</td>
              
                  <td colspan="1" class="text-center">
                    <v-chip :color="tableFooter.length > 0 && tableFooter[0].QUANTITY < 0 ? 'error' : 'success'" 
                     label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].QUANTITY : 0 }}
                    </v-chip>
                  </td>             

                  <td colspan="1" class="text-right">{{tableFooter[0].QUNIT}}</td>                 
                  <td colspan="1" class="text-center">
                    <v-chip :color="tableFooter.length > 0 && tableFooter[0].SPRICE < 0 ? 'error' : 'success'" 
                    label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].SPRICE : 0 }}
                    </v-chip>
                  </td>
                  <td colspan="1" class="text-center">
                    <v-chip :color="tableFooter.length > 0 && tableFooter[0].EXHR < 0 ? 'error' : 'success'" 
                    label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].EXHR : 0 }}
                    </v-chip>
                  </td>
                  <td colspan="1" class="text-center">
                    <v-chip  :color="tableFooter.length > 0 && tableFooter[0].GROSS < 0 ? 'error' : 'success'" 
                    label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].GROSS : 0 | currency }} 
                    </v-chip>
                  </td>
                  <td colspan="1" class="text-center">
                    <v-chip  :color="tableFooter.length > 0 && tableFooter[0].REPCURRENCY < 0 ? 'error' : 'success'" 
                        label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].REPCURRENCY : 0 }}
                    </v-chip>
                  </td>

                  <td colspan="1" class="text-center">
                    <v-chip  :color="tableFooter.length > 0 && tableFooter[0].TDISCAMNT < 0 ? 'error' : 'success'" 
                         label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].TDISCAMNT : 0 | currency }}
                    </v-chip>
                  </td>
                  <td colspan="1" class="text-center">
                    <v-chip  :color="tableFooter.length > 0 && tableFooter[0].DISCFROMHEAD < 0 ? 'error' : 'success'" 
                    label small outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].DISCFROMHEAD : 0  | currency}}
                    </v-chip>
                  </td>                

                  <td colspan="1" class="text-center">
                    <v-chip :color="tableFooter.length > 0 && tableFooter[0].SUBTOTAL < 0 ? 'error' : 'success'" 
                    small label outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].SUBTOTAL : 0  | currency}}
                    </v-chip>
                  </td>
                  <td colspan="1" class="text-right">{{tableFooter[0].CURRENCYORJ}}</td>        
               

                  <td colspan="1" class="text-center">
                    <v-chip :color="tableFooter.length > 0 && tableFooter[0].ITENDORSE < 0 ? 'error' : 'success'" 
                    small label outlined>
                      {{ tableFooter.length > 0 ? tableFooter[0].ITENDORSE : 0  | currency}}
                    </v-chip>
                  </td>
                  
            
                </tr>

                <tr class="font-weight-bold">
                    <td colspan="1" class="text-right">{{tableFooter[1].DOCDATE}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].DOCNUM}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].ITEMNUM}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].BUSAREA}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].BUSAREA}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].CUSTOMER}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                    <td colspan="1" class="text-right">{{tableFooter[1].NAME1}}</td>
                
                    <td colspan="1" class="text-center">
                      <v-chip :color="tableFooter.length > 0 && tableFooter[1].QUANTITY < 0 ? 'error' : 'success'" 
                       label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].QUANTITY : 0 }}
                      </v-chip>
                    </td>             
  
                    <td colspan="1" class="text-right">{{tableFooter[1].QUNIT}}</td>                 
                    <td colspan="1" class="text-center">
                      <v-chip :color="tableFooter.length > 0 && tableFooter[1].SPRICE < 0 ? 'error' : 'success'" 
                      label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].SPRICE : 0 }}
                      </v-chip>
                    </td>
                    <td colspan="1" class="text-center">
                      <v-chip :color="tableFooter.length > 0 && tableFooter[1].EXHR < 0 ? 'error' : 'success'" 
                      label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].EXHR : 0 }}
                      </v-chip>
                    </td>
                    <td colspan="1" class="text-center">
                      <v-chip  :color="tableFooter.length > 0 && tableFooter[1].GROSS < 0 ? 'error' : 'success'" 
                      label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].GROSS : 0 | currency }} 
                      </v-chip>
                    </td>
                    <td colspan="1" class="text-center">
                      <v-chip  :color="tableFooter.length > 0 && tableFooter[1].REPCURRENCY < 0 ? 'error' : 'success'" 
                          label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].REPCURRENCY : 0 }}
                      </v-chip>
                    </td>
  
                    <td colspan="1" class="text-center">
                      <v-chip  :color="tableFooter.length > 0 && tableFooter[1].TDISCAMNT < 0 ? 'error' : 'success'" 
                           label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].TDISCAMNT : 0 | currency }}
                      </v-chip>
                    </td>
                    <td colspan="1" class="text-center">
                      <v-chip  :color="tableFooter.length > 0 && tableFooter[1].DISCFROMHEAD < 0 ? 'error' : 'success'" 
                      label small outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].DISCFROMHEAD : 0  | currency}}
                      </v-chip>
                    </td>                
  
                    <td colspan="1" class="text-center">
                      <v-chip :color="tableFooter.length > 0 && tableFooter[1].SUBTOTAL < 0 ? 'error' : 'success'" 
                      small label outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].SUBTOTAL : 0  | currency}}
                      </v-chip>
                    </td>
                    <td colspan="1" class="text-right">{{tableFooter[1].CURRENCYORJ}}</td>        
                 
  
                    <td colspan="1" class="text-center">
                      <v-chip :color="tableFooter.length > 0 && tableFooter[1].ITENDORSE < 0 ? 'error' : 'success'" 
                      small label outlined>
                        {{ tableFooter.length > 0 ? tableFooter[1].ITENDORSE : 0  | currency}}
                      </v-chip>
                    </td>
                    
              
                  </tr>
  
              </template>

           
            </v-data-table>
          </v-card>
  
        </div>
      <!--   <div v-else>
          <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
          <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
            Lütfen yukarıdan bir müşteri seçin
          </v-alert>
        </div> -->
      </v-col>
    </v-row>
  </template>
  
  <script>
  import CustomerList from '@/components/CustomerList.vue'
  import store from '@/store'
  import { currency } from '@utils'
  import {
    mdiCamera,
    mdiCameraOff,
    mdiClose,
    mdiFinance,
    mdiDelete,
    mdiDownload,
    mdiEye,
    mdiFilter,
    mdiFilterOff,
    mdiPlusThick,
    mdiSend,
    mdiSquareEditOutline,
  } from '@mdi/js'
  import { computed,onMounted, ref, watch } from '@vue/composition-api'
  import Vue from 'vue'
  import useAppConfig from '@core/@app-config/useAppConfig'
  
  export default {
    components: {    
      CustomerList,
    },
    setup() {
      const { isDark } = useAppConfig()
      const userData = JSON.parse(localStorage.getItem('userData'))
      const status = ref(false)
      const sendData = ref({})
      const docMaterial= ref('')
      const date2 = ref(new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10))
      const date1 = ref(new Date(new Date().setDate(new Date().getDate() - 45)).toISOString().substr(0, 10))
      const menu1 = ref(false)
      const menu2 = ref(false)
      const msg = ref('Formu doldurduktan sonra ara butonuna basın')
      const tableFooter = ref([])
  
      onMounted(() => {
        /* if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
          status.value = false
        } else {
          status.value = true
          loading.value = true
          //fetchDatas()
        } */
  
  
        if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
          status.value = false
        } else {
          status.value = true
        }
      })
  
      watch(
        () => store.state.plasiyerCustomer,
        () => {
          if (store.state.plasiyerCustomer) {
            status.value = true
            //loading.value = true
            //fetchDatas()
          }
        },
      )
  
      const dataListTable = ref([])
      const dataItem = ref({})
      let tableColumns
       if (userData.role == 'PLSYR') {
        tableColumns = [
          {
            text: 'Belge Tipi',
            value: 'DOCTYPE',
            width: '90px',
            sortable: false,
          },
           {
            text: 'Belge No',
            value: 'DOCNUM',
            width: '90px',
            sortable: false,
          },
          {
            text: 'Kalem',
            value: 'ITEMNUM',
            width: '120px',
          },
          {
            text: 'Belge Tarihi',
            value: 'DOCDATE',
            width: '90px',     
          },
          {
            text: 'İş Alanı',
            value: 'BUSAREA',
            width: '90px'
          },
          {
            text: 'Müşteri',
            value: 'CUSTOMER',
           width: '90px'
          },
          {
            text: 'Müşteri Adı',
            value: 'NAME1',
            align: 'left min-width-400',
          },
          {
            text: 'Ana Müşteri',
            value: 'CENTRAL',
           width: '90px'
          },
          
          {
            text: 'M.Grup',
            value: 'CUSTGRP',
            width: '150px',
            align: 'right',
            sortable: false,
          },
  
          {
            text: 'Ülke',
            value: 'COUNTRY',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Satış Dep.',
            value: 'SALDEPT',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Satış Bl. Açk.',
            value: 'SALDTXT',
            width: '150px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Malzeme',
            value: 'MATERIAL',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Malzeme Adı',
            value: 'MTEXT',
            width: '250px',
            align: 'center',
            sortable: false,
          },
  
          {
            text: 'Malzeme Tipi',
            value: 'MATTYPE',
            sortable: false,
            width: '100px',
          }, 
          {
            text: 'Malzeme Grubu',
            value: 'MATGRP',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Belge P.B.',
            value: 'DOCCURRENCY',
            width: '100px',
            align: 'center',
            sortable: false,
          },
       
          {
            text: 'Miktar',
            value: 'QUANTITY',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Miktar Br.',
            value: 'QUNIT',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Fiyat',
            value: 'SPRICE',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Bb',
            value: 'EXHR',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Brüt',
            value: 'GROSS',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Rapor Prb.',
            value: 'REPCURRENCY',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'İnd/Art Top.',
            value: 'TDISCAMNT',
            width: '100px',
            align: 'center',
            sortable: false,
          }, 
          {
            text: 'Belg. İnd.',
            value: 'DISCFROMHEAD',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Ciro Tutarı',
            value: 'SUBTOTAL',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          {
            text: 'P. Br.',
            value: 'CURRENCYORJ',
            width: '100px',
            align: 'center',
            sortable: false,
          },     
          {
            text: 'Tutar (B.P.Br)',
            value: 'ITENDORSE',
            width: '100px',
            align: 'center',
            sortable: false,
          },
          
        ]
      } 
  
      const totalDataListTable = ref(0)
      const loading = ref(false)
      const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
      })
      const dataTotalLocal = ref([])
      const selectedRows = ref([])
  
      const json_data = ref([])
  
      const fetchDatas = () => {
        debugger
        const [day, month, year] = computedDateFormatted.value.split('/')
        const newEndDate = `${year}-${month}-${day}`
  
        const [day1, month1, year1] = computedDateFormatted1.value.split('/')
        const newStartDate = `${year1}-${month1}-${day1}`
    
        msg.value = 'Arama yapılıyor lütfen bekleyin'
        dataListTable.value = []
        tableFooter.value = []
        loading.value = true      
        store
        .dispatch('postMethod', {
            method: 'getERPSalesReport',
            'search[startDate]': newStartDate,
            'search[endDate]': newEndDate,
            'search[material]': docMaterial.value
          })
          .then(response => {
            
            if (response.error == 0) {            
                debugger
             //dataListTable.value = response.detail
             //totalDataListTable.value = Number(response.response.pagination.totalRec)
             //json_data.value = response.detail.result
            
             // status.value = false
             // loading.value = false
             response.detail.filter(extracts)
                loading.value = false
                status.value = false

            } else {
              loading.value = false
              Vue.swal({
                title: 'Hata',
                text: 'Bir Hata Oluştu',
                width: '350px',
                icon: 'error',
                background: '#FF4C51',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#cc3d41',
              })
            }
          })
          .catch(e => {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          })
      }
  

      const extracts = (items, index, array) => {
    const DOCDATE = items.DOCDATE.slice(0, 10).replaceAll(' ', '');
    const item = {
        DOCDATE,
        DOCTYPE: items.DOCTYPE,
        DOCNUM: items.DOCNUM,
        ITEMNUM: items.ITEMNUM,
        BUSAREA: items.BUSAREA,
        CUSTOMER: items.CUSTOMER,
        NAME1: items.NAME1,
        CENTRAL: items.CENTRAL,
        CUSTGRP: items.CUSTGRP,
        COUNTRY: items.COUNTRY,
        SALDEPT: items.SALDEPT,
        SALDTXT: items.SALDTXT,
        MATERIAL: items.MATERIAL,
        MTEXT: items.MTEXT,
        MATTYPE: items.MATTYPE,
        MATGRP: items.MATGRP,
        SPRICE: currency.format(items.SPRICE), // Formatlanmış fiyat
        SUBTOTAL: items.SUBTOTAL,
        QUNIT: items.QUNIT, // Eklenen alanlar
        QUANTITY: items.QUANTITY,
        EXHR: currency.format(items.EXHR),
        GROSS: currency.format(items.GROSS),
        REPCURRENCY: items.REPCURRENCY,
        TDISCAMNT: items.TDISCAMNT,
        DISCFROMHEAD: items.DISCFROMHEAD,
        CURRENCYORJ: items.CURRENCYORJ,
        ITENDORSE: currency.format(items.ITENDORSE),
        DOCCURRENCY: items.DOCCURRENCY,
    };

    if (index < array.length - 2) {
        // Veriyi listeye ekle
        dataListTable.value.push(item);
        json_data.value.push(item);
    } else if (index === array.length - 2) {
      json_data.value.push(item);
        // "TOPLAM" satırını ekle
        const totalSum = array.reduce((sum, item) => sum + parseFloat(item.SPRICE), 0);

         // SUBTOTAL kontrolü
    const lastItem = array[array.length - 2];
    const subtotal = lastItem.SUBTOTAL ? lastItem.SUBTOTAL : 0; // Eğer SUBTOTAL yoksa 0 kullan
    
    console.log('Last item:', lastItem);
    console.log('Last item SUBTOTAL:', subtotal); // SUBTOTAL'ı konsolda kontrol et

    const totalRow = {
            DOCDATE: 'TOPLAM',
            SPRICE: lastItem.SPRICE,
            SUBTOTAL: subtotal,
            QUNIT: lastItem.QUNIT,
            QUANTITY: lastItem.QUANTITY,
            EXHR: lastItem.EXHR,
            GROSS: lastItem.GROSS,
            REPCURRENCY: lastItem.REPCURRENCY,
            TDISCAMNT: lastItem.TDISCAMNT,
            DISCFROMHEAD: lastItem.DISCFROMHEAD,
            CURRENCYORJ: lastItem.CURRENCYORJ,
            ITENDORSE: lastItem.ITENDORSE,
        };
        tableFooter.value.push(totalRow);
        json_data.value.push(totalRow); // JSON'a da ekle

    } else if (index === array.length - 1) {
        // "GENEL TOPLAM" satırını ekle
     
        const lastItem = array[array.length - 1];
        const subtotal = lastItem.SUBTOTAL ? lastItem.SUBTOTAL : 0; // Eğer SUBTOTAL yoksa 0 kullan

        const generalTotalRow = {
            DOCDATE: 'GENEL TOPLAM',
            SPRICE: lastItem.SPRICE,
            SUBTOTAL: subtotal,
            QUNIT: lastItem.QUNIT,
            QUANTITY: lastItem.QUANTITY,
            EXHR: lastItem.EXHR,
            GROSS: lastItem.GROSS,
            REPCURRENCY: lastItem.REPCURRENCY,
            TDISCAMNT: lastItem.TDISCAMNT,
            DISCFROMHEAD: lastItem.DISCFROMHEAD,
            CURRENCYORJ: lastItem.CURRENCYORJ,
            ITENDORSE: lastItem.ITENDORSE,
        };
        tableFooter.value.push(generalTotalRow);
        json_data.value.push(generalTotalRow); // JSON'a da ekle
    }
};



      const formatDate = dates => {
        if (!dates) return null
        const [year, month, day] = dates.split('-')
        return `${day}/${month}/${year}`
      }
  
      let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))
  
      const parseDate = dates => {
        if (!dates) return null
        const [month, day, year] = dates.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
  
      const computedDateFormatted = computed(() => formatDate(date2.value))
      const computedDateFormatted1 = computed(() => formatDate(date1.value))
  
      watch(date2, () => {
        dateFormatted = formatDate(date2.value)
      })
  
      return {
        sendData,
        json_data,
        dataItem,
        dataListTable,
        tableColumns,
        totalDataListTable,
        loading,
        options,
        dataTotalLocal,
        selectedRows,
        fetchDatas,
        status,
        userData,
  
        menu1,
        menu2,
        date2,
        date1,
        dateFormatted,
        computedDateFormatted,
        computedDateFormatted1,
        parseDate,
        formatDate,
        msg,
        isDark,
        tableFooter,
        docMaterial,
        // icons
        icons: {
          mdiCamera,
          mdiDelete,
          mdiCameraOff,
          mdiFinance,
          mdiEye,
          mdiFilter,
          mdiFilterOff,
          mdiPlusThick,
          mdiSquareEditOutline,
          mdiClose,
          mdiDownload,
          mdiSend,
        },
      }
    },
    data() {
      return {
        panel: null,
        date: null,
        modal: false,
        filterStatus: false,
        dataTableHeight: '',
        height: {
          system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? (this.userData.role === 'PLSYR' ? 140 : 48) : 100,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
        },
        json_fields: {
        'Belge Tipi': 'DOCTYPE',
        'Belge No': 'DOCNUM',
        'Kalem': 'ITEMNUM',
        'Belge Tarihi': 'DOCDATE',
        'İş Alanı': 'BUSAREA',
        'Müşteri': 'CUSTOMER',
        'Müşteri Adı': 'NAME1',
        'Ana Müşteri': 'CENTRAL',
        'M.Grup': 'CUSTGRP',
        'Ülke': 'COUNTRY',
        'Satış Dep.': 'SALDEPT',
        'Satış Bl. Açk.': 'SALDTXT',
        'Malzeme': 'MATERIAL',
        'Malzeme Adı': 'MTEXT',
        'Malzeme Tipi': 'MATTYPE',
        'Malzeme Grubu': 'MATGRP',
        'Belge P.B.': 'DOCCURRENCY',
        'Miktar': 'QUANTITY',
        'Miktar Br.': 'QUNIT',
        
        Fiyat: {
          field: 'SPRICE',
          callback: value => {
            if (!value) return "0"; // Eğer değer boşsa 0 döndür
            let cleanedValue = value.replace(/[^\d,.-]/g, ''); // Sadece rakamları, virgül ve noktayı tut
            cleanedValue = cleanedValue.replace(/\./g, '').replace(',', '.'); // Noktaları kaldır, virgülü noktaya çevir
            return cleanedValue;
          }
        },

        'Bb': 'EXHR',
        'Brüt': 'GROSS',
        'Rapor Prb.': 'REPCURRENCY',
        'İnd/Art Top.': 'TDISCAMNT',
        'Belg. İnd.': 'DISCFROMHEAD',
        'Ciro Tutarı': 'SUBTOTAL',
        'P. Br.': 'CURRENCYORJ',
       
        'Tutar (B.P.Br)': {
          field: 'ITENDORSE',
          callback: value => {
            if (!value) return "0"; // Eğer değer boşsa 0 döndür
            let cleanedValue = value.replace(/[^\d,.-]/g, ''); // Sadece rakamları, virgül ve noktayı tut
            cleanedValue = cleanedValue.replace(/\./g, '').replace(',', '.'); // Noktaları kaldır, virgülü noktaya çevir
            return cleanedValue;
          }
        },       
      },
        json_meta: [
          [
            {
              key: 'charset',
              value: 'utf-8',
            },
          ],
        ],
      }
    },
  
   /*  watch: {
      panel(val) {
        if (val === 0) {
          setTimeout(() => {
            this.height.top = 250
            this.onResize()
          }, 300)
        } else {
          this.height.top = 48
          this.onResize()
        }
      },
    }, */
  
    mounted() {
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
      })
    },
  
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },
    methods: {
      onResize() {
        this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
      },
      getTotalRowStyle(index) {
      if (index === this.dataListTable.length - 1 || index === this.dataListTable.length - 2) {
        return {
          backgroundColor: 'grey',
          color: 'red',
          border: '1px solid red',
        };
      }
      return {};
    },

    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/preset/preset/apps/user.scss';

  .total-row {
    background-color: grey !important; /* Arka planı gri yapıyoruz */
    border: 2px solid white !important; /* Çerçeveyi beyaz yapıyoruz */
  }
  
  .red--text {
    color: red !important;  /* Yazıyı kırmızı yapıyoruz */
  }
  

.table-header,
.table-footer {
    display: flex;
    justify-content: space-between; /* Kolonlar arasında eşit boşluk bırak */
}
  </style>
  